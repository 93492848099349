<template>
  <div>
    <div>
 
      <!-- modal Scrolling Content inside Modal-->
      <b-modal
          v-model="modalShow"
          id="modal-scrollable"
          scrollable
          :title="'Choisir véhicule ('+ pagination.total + ')'"
          cancel-title="Fermer"
          ok-title="Choisir"
          cancel-variant="outline-secondary"
          size="lg"
          @ok="add_vehicule"
      >

        <b-form @submit="onSubmit" class="px-1">
          <b-form-group>
            <label>Recherche :</label>
            <b-form-input
                v-model="searchTxt"
                id="search"
                placeholder="Recherche "
            />
          </b-form-group>

        </b-form>
        <div class="media-list media-bordered">

          <b-card-text>
            <div @click="vehiculeClickHandler(item)" :class="{selected : selected.id == item.id, disabled : disabledVehicules.includes(item.id) }" v-for="item in vehicules"
                     class="media">

              <slot :item="item" />
            </div>
          </b-card-text>

        </div>
      </b-modal>

    </div>

    <b-button size="sm" :disabled="disabled" @click="modalShow = !modalShow" variant="primary">{{titleButton}}</b-button>

  </div>
</template>

<script>
import axios from "@/libs/axios";

export default {
  name: "VehiculeSelectorModal",
  props: {
    query : {
      type : Object,
      default() {
        return {};
      }
    },
    titleButton : {
      default : 'Ajouter véhicule'
    },
    disabledVehicules : {
      type : Array,
      default(){
        return [];
      }
    },
    modalId: {
      required: true,
    },
    fetchUrl : {
      required : true,
      default : '/api/vehicules/catalogue'
    },
    disabled : {
      default : false
    }
  },
  watch: {
    modalShow(newVal, oldVal) {
      if (newVal == true && oldVal==false) {
        this.fetch(1);
      }
    }
  },
  data() {
    return {
      modalShow: false,
      vehicules: [],
      searchTxt: '',
      pagination: {},
      selected: {},
      

    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.fetch(1);
    },
    fetch(page) {
      let _this = this;
      let query = this.query
      this.selected = {};
      axios
          .get(_this.fetchUrl, {
            params: {
              page: page,
              q: this.searchTxt,
              ...query
            }
          })
          .then(function (response) {
            _this.vehicules = response.data.data;
            _this.pagination = response.data.meta;
              
          });
 
    },
    add_vehicule(event) {
      if(this.selected.id){
        this.$emit('vehiculeSelected', this.selected)
      }

      console.log('---------');
      return false;
    },
    vehiculeClickHandler(item){
      if(!this.disabledVehicules.includes(item.id)){
        this.selected = item;

      }
    },

  }
}
</script>

<style scoped>
.media.disabled{
  background-color: #d5D5D5;
  cursor: default !important;
}
.media.selected {
  background-color: rgb(120 210 40 / 16%);
}
.media{
  cursor: pointer;
}
</style>