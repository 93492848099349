<template>
  <b-row v-if="offre">

    <b-col md="9">

      <b-card no-body v-if="offre.type == 'vente'">
        <b-card-header>
          <b-card-title>
            Ventes
          </b-card-title>
          <b-button v-if="offre.ventes.length != 0"
                    @click="supprimer_vehicule_vente(offre.ventes[0])"
                    variant="flat-danger"
                    :disabled="!editable"
                    size="sm"
          >
            <feather-icon icon="XIcon"/>
            Supprimer le véhicule
          </b-button>
          <vehicule-selector-modal v-if="offre.ventes.length == 0"
                                   :disabled-vehicules="offre.ventes.map(offre_vente => offre_vente.vehicule.id)"
                                   fetch-url="/api/vehicules/catalogue" @vehiculeSelected="ajouter_vehicule_vente"
                                   modal-id="modal-ajouter-vehicule-vente">
            <template #default="{item}">
              <vehicule-overview :clickable="false" :vehicule="item"/>
            </template>
          </vehicule-selector-modal>
        </b-card-header>
        <b-card-body v-if="offre.ventes.length">
          <div :key="offre_vente.id" v-for="offre_vente in offre.ventes">
            <b-row>
              <b-col md="8">
                <vehicule-overview :vehicule="offre_vente.vehicule"/>
              </b-col>
              <b-col md="4">
                <div class="mb-1">
                  <label>Prix</label>
                  <b-input-group
                      label="Prix"
                      prepend="MAD"
                  >

                    <b-form-input
                        :disabled="!editable"
                        v-model="offre_vente.prix"
                        type="number"
                        placeholder="Prix"
                    />

                  </b-input-group>
                </div>
                <div>
                  <b-form-group label="Garantie" class="mb-0" label-for="garantie">
                    <v-select id="garantie"
                              :disabled="!editable"
                              :options="$store.state.initialConfigModule.initialState.vehicule_garanties"
                              v-model="offre_vente.garantie"
                              label="libelle"/>
                  </b-form-group>
                </div>

              </b-col>

            </b-row>
          </div>
        </b-card-body>


      </b-card>
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Reprise
          </b-card-title>
          <vehicule-selector-modal :disabled="!editable" v-if="offre.reprises.length == 0"
                                   title-button="Ajouter un véhicule à reprendre"
                                   :disabled-vehicules="offre.reprises.map(reprise => reprise.vehicule.id)"
                                   :query="{statut_vente : 1}" fetch-url="/api/vehicules/reprise?only_with_offre=1"
                                   @vehiculeSelected="ajouter_vehicule_reprise"
                                   modal-id="modal-ajouter-vehicule-reprise">
            <template #default="{item}">
              <vehicule-overview :clickable="false" :reprise="item" :vehicule="item.vehicule"/>
            </template>
          </vehicule-selector-modal>
          <b-button
              :disabled="!editable"
              v-if="offre.reprises.length != 0"
              @click="supprimer_vehicule_reprise(offre.reprises[0])"
              variant="flat-danger"
              size="sm"
          >
            <feather-icon icon="XIcon"/>
            Supprimer le véhicule
          </b-button>
        </b-card-header>

        <b-card-body v-if="offre.reprises.length != 0">
          <div class="mb-1 d-flex " v-for="item in offre.reprises">
            <div class="flex-grow-1">
              <vehicule-overview :vehicule="item.vehicule"/>
            </div>
            <div class="border-left flex-grow-1 pl-2">

              <div><span class="font-medium-1 font-weight-bold">Frais</span> :
                {{ item.expertise_manuelle.total.toLocaleString('fr') }} Dhs <br/>
              </div>
              <div><span class="font-medium-1 font-weight-bold"> Offre</span> :
                {{ item.offre_en_cours.prix.toLocaleString('fr') }} Dhs <small
                    class="text-muted">{{ item.offre_en_cours.par }}</small></div>
            </div>

          </div>

        </b-card-body>


      </b-card>
      <b-card v-if="offre.type == 'vente'" no-body>
        <b-card-title class="pl-1 pr-1 pt-1 mb-0">Frais</b-card-title>
        <b-card-body class="p-1">
          <div>
            <b-row class="">

              <b-col md="2">Libellé</b-col>
              <b-col md="4">Prix Unitaire</b-col>
              <b-col md="3">Quantité</b-col>
              <b-col md="2">Total</b-col>
              <b-col md="1"></b-col>

            </b-row>
            <b-row :key="index" v-for="(item, index) in offre.frais">
              <b-col md="2">
                <b-form-input
                    id="item-name"
                    size="sm"
                    type="text"
                    :disabled="!editable"
                    v-model="item.libelle"
                    placeholder="Libellé"
                />
              </b-col>
              <b-col md="4">
                <b-form-input
                    id="cost"
                    size="sm"
                    type="number"
                    :disabled="!editable"
                    v-model="item.prix_unitaire"
                    placeholder="100"
                />
              </b-col>
              <b-col md="3">
                <b-form-input
                    id="quantity"
                    size="sm"
                    type="number"
                    :disabled="!editable"
                    v-model="item.qte"
                    placeholder="1"
                />
              </b-col>
              <b-col md="2">
                <b-form-input
                    id="pzrice"
                    :value="((item.qte && item.prix_unitaire) ? item.qte * item.prix_unitaire : 0) + ' Dhs'"
                    plaintext
                />
              </b-col>
              <b-col md="1">
                <b-button
                    @click="offre.frais.splice(index, 1)"
                    variant="flat-danger"
                    :disabled="!editable"
                    class="btn-icon rounded-circle"
                >
                  <feather-icon icon="XIcon"/>
                </b-button>
              </b-col>
            </b-row>
            <hr/>
            <div class="text-right">
              <b-button :disabled="!editable" @click="offre.frais.push({})" class="mr-1" size="sm">Ajouter</b-button>
              <b-button :disabled="!editable" @click="enregistrerFrais" class="" variant="primary" size="sm">
                Enregistrer
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
      <b-card v-if="offre.type == 'vente'" no-body>
        <b-card-title class="pl-1 pr-1 pt-1 mb-0">Accessoire</b-card-title>
        <b-card-body class="p-1">
          <div>
            <b-row class="">

              <b-col md="2">Libellé</b-col>
              <b-col md="4">Prix Unitaire</b-col>
              <b-col md="3">Quantité</b-col>
              <b-col md="2">Total</b-col>
              <b-col md="1"></b-col>

            </b-row>
            <b-row :key="index" v-for="(item, index) in offre.accessoires">
              <b-col md="2">
                <b-form-input
                    id="item-name"
                    size="sm"
                    :disabled="!editable"
                    type="text"
                    v-model="item.libelle"
                    placeholder="Libellé"
                />
              </b-col>
              <b-col md="4">
                <b-form-input
                    id="cost"
                    size="sm"
                    type="number"
                    :disabled="!editable"
                    v-model="item.prix_unitaire"
                    placeholder="100"
                />
              </b-col>
              <b-col md="3">
                <b-form-input
                    id="quantity"
                    size="sm"
                    type="number"
                    :disabled="!editable"
                    v-model="item.qte"
                    placeholder="1"
                />
              </b-col>
              <b-col md="2">
                <b-form-input
                    id="pzrice"
                    :value="((item.qte && item.prix_unitaire) ? item.qte * item.prix_unitaire : 0) + ' Dhs'"
                    plaintext
                />
              </b-col>
              <b-col md="1">
                <b-button
                    :disabled="!editable"
                    @click="offre.accessoires.splice(index, 1)"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                >
                  <feather-icon icon="XIcon"/>
                </b-button>
              </b-col>
            </b-row>
            <hr/>
            <div class="text-right">
              <b-button :disabled="!editable" @click="offre.accessoires.push({})" class="mr-1" size="sm">Ajouter
              </b-button>
              <b-button :disabled="!editable" @click="enregistrerAccessoire" class="" variant="primary" size="sm">
                Enregistrer
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="3">
      <client-card @client-selected="clientSelectedHandler" :editable="editable" :initial-client="offre.client"/>
      <b-card title="Accompte" v-if="offre.ventes.length">
        <b-input-group>
          <b-form-input :disabled="!editable" v-model="offre.accompte" type="number" id="accompte"
                        placeholder="Accompte"/>
          <b-input-group-append>
            <b-button :disabled="!editable" @click="enregistrerAccompte" variant="outline-primary">
              Enregistrer
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </b-card>
      <b-card>

        <div class="price-details">
          <h5 v-if="offre.ventes.length" class="border-bottom pb-1">Vente</h5>
          <ul v-if="offre.ventes.length" class="list-unstyled">
            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title">
                Prix
              </div>
              <div class="detail-amt">
                {{ total_prix_vehicule.toLocaleString('en') }} Dhs
              </div>
            </li>

            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title">
                Accessoire
              </div>
              <div class="detail-amt">
                {{ total_prix_accessoire.toLocaleString('en') }} Dhs
              </div>
            </li>
            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title">
                Frais
              </div>
              <div class="detail-amt ">
                {{ total_prix_frais.toLocaleString('en') }} Dhs
              </div>
            </li>
            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title">
                Accompte
              </div>
              <div class="detail-amt ">
                {{ offre.accompte.toLocaleString('en') }} Dhs
              </div>
            </li>
            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title text-success">
                Total vente
              </div>
              <div class="detail-amt ">
                {{
                  (total_prix_vehicule + total_prix_accessoire + total_prix_frais - offre.accompte).toLocaleString('en')
                }} Dhs
              </div>
            </li>
          </ul>
          <h5 class="border-bottom pb-1">Reprise</h5>

          <ul class="list-unstyled border-bottom border-3">
            <li v-if="total_prix_expertise" class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title">
                Offre de reprise
              </div>
              <div class="detail-amt">
                {{ total_prix_reprise.toLocaleString('en') }} Dhs
              </div>
            </li>

            <li class="price-detail mb-1 d-flex justify-content-between">

              <div class="detail-title">
                Frais de remise en état
              </div>
              <div class="detail-amt">
                {{ total_prix_expertise.toLocaleString('en') }} Dhs
              </div>
            </li>
            <li class="price-detail mb-1 d-flex justify-content-between text-danger">

              <div class="detail-title">
                Total reprise
              </div>
              <div class="detail-amt">
                {{ (total_prix_reprise - total_prix_expertise).toLocaleString('en') }} Dhs
              </div>
            </li>

          </ul>

          <ul class="list-unstyled">
            <li class="price-detail mb-1 d-flex justify-content-between">
              <div class="detail-title detail-total">
                {{ net_a_payer > 0 ? 'Net à payer' : 'Reste à payer' }}

              </div>
              <div class="detail-amt font-weight-bolder">
                {{
                  Math.abs(((total_prix_vehicule + total_prix_accessoire + total_prix_frais - offre.accompte) - (total_prix_reprise - total_prix_expertise))).toLocaleString('en')
                }} Dhs
              </div>
            </li>
          </ul>

        </div>

      </b-card>

    </b-col>

    <b-col v-if="offre.str_statut.str_id" md="12">
      <b-card no-title>
        <div class="d-flex justify-content-between">
          <div class="text-left">
            <b-dropdown
                split
                text="Imprimer"
                variant="primary"
                right
                v-if="offre.documents.length"
            >
              <!--      <b-dropdown-item target="_blank" :href="item.path" v-for="item in offre.documents">-->
              <!--        {{item.title}}-->
              <!--      </b-dropdown-item>-->
              <b-dropdown-item-button @click="printClickHandler(item)" v-for="item in offre.documents">
                {{ item.title }}
              </b-dropdown-item-button>
            </b-dropdown>

            <b-modal id="print-modal" title="Bon de sortie" @ok="print_bon_sortie">
              
              <b-form-group label="Motif" label-for="print-select">
                <b-form-select id="print-select" v-model="bon_sortie_motif" >
                  <option value="0">Carburant</option>
                  <option value="1">Livraison définitive</option>
                  <option value="2">Réparation extérieur</option>
                  <option value="3">Visite technique</option>
                </b-form-select>
              </b-form-group>
            </b-modal>
          </div>
          <div class="text-right">
            <b-button :disabled="!can_annuler_offre" @click="annuler_offre" class="mr-1" variant="danger">
              Annuler l'offre
            </b-button>
            <b-button :disabled="!can_cloturer_bdc" @click="cloturer_bon_commande" class="mr-1" variant="success">
              Clôturer BC
            </b-button>


          </div>

        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VehiculeSelectorModal from "@/components/Vehicule/VehiculeSelectorModal";
import axios from "@/libs/axios";
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import ClientCard from "@/components/ClientCard";

export default {
  components: {
    ClientCard,
    VehiculeOverview,
    VehiculeSelectorModal
  },
  name: "OffreViewPage",
  data() {
    return {
      offre: false,
      clients: [],
      bon_sortie_motif : '0',
      bon_sortie_path: '',
    }
  },
  computed: {
    editable() {
      if (this.offre.str_statut.str_id == 'offre_en_cours'
          || this.offre.str_statut.str_id == 'bdc_en_cours'
      ) {
        return true;
      }
      return false;
    },
    can_generer_bdc() {
      if (this.offre.str_statut.str_id == 'offre_en_cours') {
        return true;
      }
      return false;
    },
    can_cloturer_bdc() {
      if (this.offre.str_statut.str_id == 'bdc_en_cours') {
        return true;
      }
      return false;
    },
    can_save() {
      if (this.offre.str_statut.str_id == 'bdc_en_cours') {
        return true;
      }
      return false;
    },
    can_annuler_offre() {


      if (this.offre.str_statut.str_id != 'offre_annuler'

      ) {
        return true;
      }
      return false;
    },
    total_prix_expertise() {
      let total = 0;
      this.offre.reprises.forEach(function (item) {
        total += item.expertise_manuelle.total * 1;
      });
      return total;
    },
    total_prix_reprise() {
      let total = 0;
      this.offre.reprises.forEach(function (item) {
        total += item.offre_en_cours.prix * 1;
      });
      return total;
    },
    total_prix_vehicule() {
      let total = 0;
      this.offre.ventes.forEach(function (item) {
        total += item.prix * 1;
      });
      return total * 1;
    },
    total_prix_accessoire() {
      let total = 0;
      this.offre.accessoires.forEach(function (item) {
        if (item.prix_unitaire && item.qte) {
          total += item.prix_unitaire * item.qte;
        }
      });
      return total * 1;
    },
    total_prix_frais() {
      let total = 0;
      this.offre.frais.forEach(function (item) {
        if (item.prix_unitaire && item.qte) {
          total += item.prix_unitaire * item.qte;
        }
      });
      return total * 1;
    },
    net_a_payer() {
      //return  this.total_prix_vehicule; 
      //return  this.total_prix_accessoire; 
      //return  this.total_prix_frais; 
      //return  this.offre.accompte; 
      //return  this.total_prix_reprise; 
      //return  this.total_prix_expertise; 
      //(1 + 2 + 3 - 4) - (23000 - 1000)
      let total_vente = ((this.total_prix_vehicule * 1) + (this.total_prix_accessoire * 1) + (this.total_prix_frais * 1) + (this.total_prix_expertise * 1)) - ((this.offre.accompte * 1) + (this.total_prix_reprise * 1));
      let stotal_vente = ((this.total_prix_vehicule * 1) + (this.total_prix_accessoire * 1) + (this.total_prix_frais * 1) + (this.total_prix_expertise * 1)) - (this.offre.accompte * 1) - (this.total_prix_reprise * 1);

      return stotal_vente;
    }

  },
  mounted() {
    let _this = this;
    this.$store.dispatch('offreModule/fetch', {
      offre_id: _this.$route.params.id
    }).then(function (response) {
      _this.offre = response.data;
    })
  },
  methods: {
    printClickHandler(item) {

      if(this.offre.str_statut.id != 2){
        this.$bvModal.msgBoxOk('Merci de clôturer le bon de commande')
        return  false;
      }
      
      this.bon_sortie_path = item.path;
      if (item.form) {
        
        this.$bvModal.show('print-modal')
      } else {
        
        window.open(item.path, '_blank');
        
      }
      return false;
    },
    print_bon_sortie(){
      window.open(this.bon_sortie_path + '?motif=' + this.bon_sortie_motif, '_blank');
      console.log('bon_sortie_path');
    },
    supprimer_vehicule_vente(vehicule) {
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment supprimer le véhicule ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              _this.$store.dispatch('offreModule/deleteVehiculeVente', {
                vehicule_vente_id: vehicule.id
              }).then(function (response) {

                _this.offre = response.data;
              })
            }
          });
    },
    supprimer_vehicule_reprise(reprise) {
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment supprimer le véhicule ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              _this.$store.dispatch('offreModule/deleteVehiculeReprise', {
                vehicule_reprise_id: reprise.id
              }).then(function (response) {

                _this.offre = response.data;
              })
            }
          });
    },
    ajouter_vehicule_vente(vehicule) {
      let _this = this;

      this.$store.dispatch('offreModule/addVehicule', {
        offre_id: _this.$route.params.id,
        vehicule: vehicule
      }).then(function (response) {
        _this.offre = response.data;
      });
    },
    ajouter_vehicule_reprise(vehicule) {
      let _this = this;
      axios
          .post('/api/offres/' + _this.$route.params.id + '/ajouter-vehicule-reprise', {
            vehicule
          })
          .then(function (response) {
            _this.offre = response.data.data;
          });
    },
    enregistrer() {
      this.$store.dispatch('offreModule/store', this.offre);
    },
    enregistrerAccessoire() {
      let data = {
        offre_id: this.offre.id,
        accessoires: this.offre.accessoires
      };
      axios
          .post('/api/offres/enregistrer-accessoire', data)
          .then(function () {

          });
      console.log('enregistrerAccessoire');
    },
    enregistrerFrais() {
      let data = {
        offre_id: this.offre.id,
        frais: this.offre.frais
      };
      axios
          .post('/api/offres/enregistrer-frais', data)
          .then(function () {

          });
      console.log('enregistrerFrais');
    },
    enregistrerAccompte() {
      let data = {
        offre_id: this.offre.id,
        accompte: this.offre.accompte
      };
      axios
          .post('/api/offres/enregistrer-accompte', data)
          .then(function () {

          });
      console.log('enregistrerAccompte');
    },
    fetchClients(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        'q': search
      }).then(function (response) {
        _this.clients = response.data;
      });
    },
    generer_bon_commande() {

      let _this = this;
      axios
          .post('/api/offres/' + _this.$route.params.id + '/generer-bon-commande')
          .then(function (response) {
            _this.offre = response.data.data;
          });
    },
    cloturer_bon_commande() {
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment cloturer le BC ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              axios
                  .post('/api/offres/' + _this.$route.params.id + '/cloturer-bon-commande')
                  .then(function (response) {
                    _this.offre = response.data.data;
                  });
            }
          });

    },
    annuler_offre() {
      let _this = this;
      this.$bvModal
          .msgBoxConfirm('Voulez vous vraiment annuler ?', {
            title: 'Supprimer véhicule',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              axios
                  .post('/api/offres/' + _this.$route.params.id + '/annuler-offre')
                  .then(function (response) {
                    _this.offre = response.data.offre;
                  });
            }
          });

    },
    clientSelectedHandler(client) {
      this.offre.client = client;
      let _this = this;
      axios
          .post('/api/offres/' + _this.$route.params.id + '/update-client', {
            client_id: client.id
          })
          .then(function (response) {
            _this.offre = response.data.data;
          });
    }
  }
}
</script>

<style scoped>

</style>